import { useState, useEffect, useMemo } from "react"
import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import useElementSize from "../hooks/useElementSize"

import { useIntl } from "gatsby-plugin-intl"

const TrashContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  border: 2px solid #84b250;
  border-radius: 20px;
  width: min(15vw, 25vh);
  height: min(15vw, 25vh);
  margin: min(calc(15vw * 0.04), calc(25vh * 0.04));

  background-color: #ffffff;
  @media (max-width: 991px) {
    margin: 0.8vw;
    width: 29vw;
    height: 29vw;
    border: 1px solid #84b250;
  }
`
const TrashImage = styled.img`
  height: 80%;
`
const TrashNameContainer = styled.div`
  position: absolute;
  width: 100%;
  top: min(calc(15vw * 0.03), calc(25vh * 0.03));
  margin-top: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #4e4e4e;
  padding: 0 min(calc(15vw * 0.03), calc(25vh * 0.03));
  font-size: ${props =>
    props.fontSize
      ? `${props.fontSize}px`
      : `min(calc(15vw * 0.06), calc(25vh * 0.06))`};
  line-height: ${props =>
    props.fontSize
      ? `${props.fontSize}px`
      : `min(calc(15vw * 0.06), calc(25vh * 0.06))`};
  font-weight: bold;
  text-transform: uppercase;
  word-wrap: break-word;
  &:hover {
    color: #4e4e4e;
  }
  @media (max-width: 576px) {
    font-size: ${props =>
      props.fontSize
        ? `${props.fontSize}px`
        : `min(calc(20vw * 0.06), calc(30vh * 0.06))`};
    line-height: ${props =>
      props.fontSize
        ? `${props.fontSize}px`
        : `min(calc(20vw * 0.06), calc(30vh * 0.06))`};
  }
`
const ContainerStyle = styled(Container)`
  background-color: #f8f8f8;
`

const TrashItem = ({ searchResult, index }) => {
  const intl = useIntl()
  const trashRef = React.useRef()
  const nameRef = React.useRef()
  const { height: trashHeight } = useElementSize(trashRef)
  const { height: nameHeight } = useElementSize(nameRef)
  const [fontSize, setFontSize] = useState(undefined)

  useEffect(() => {
    setFontSize(prev => {
      if (!prev) {
        return trashHeight * 0.08
      }

      if (nameHeight > trashHeight * 0.16) {
        return prev - trashHeight * 0.005
      }

      return prev
    })
  }, [nameHeight, trashHeight])

  return (
    <Link
      ref={trashRef}
      to={
        "/" +
        intl.locale +
        searchResult.fields.slug.replace(
          "/" + searchResult.frontmatter.language,
          ""
        )
      }
      itemProp="url"
      id={`trashLink${index}`}
    >
      <TrashContainer
        key={searchResult.fields.slug.replace(
          "/" + searchResult.frontmatter.language,
          ""
        )}
      >
        <TrashNameContainer ref={nameRef} fontSize={fontSize}>
          <p>{searchResult.frontmatter.title}</p>
        </TrashNameContainer>
        <TrashImage
          src={searchResult.frontmatter.trashimage.publicURL}
          alt={searchResult.fields.slug.replace(
            "/" + searchResult.frontmatter.language,
            ""
          )}
        />
      </TrashContainer>
    </Link>
  )
}

const Mainpage = ({ data }) => {
  const intl = useIntl()
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMarkdownRemark.nodes
  // const [posts, setPosts] = useState(data.english.nodes)
  // console.log(posts)

  const posts = useMemo(() => {
    return data[intl.locale].nodes
  }, [data, intl.locale])

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState(posts)
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  const trashContainerParentRef = React.useRef()
  const [trashContainerWidth, setTrashContainerWidth] = useState(undefined)

  const resizeTrashContainerWidth = React.useCallback(() => {
    const trashWidth = document
      .getElementById("trashLink0")
      .getBoundingClientRect().width
    const containerWidth = trashContainerParentRef.current.clientWidth
    const perRow = Math.floor(containerWidth / trashWidth)

    setTrashContainerWidth(trashWidth * perRow)
  }, [])

  useEffect(() => {
    resizeTrashContainerWidth()

    window.addEventListener("resize", resizeTrashContainerWidth)

    return () => {
      window.removeEventListener("resize", resizeTrashContainerWidth)
    }
  }, [resizeTrashContainerWidth])

  useEffect(() => {
    const result = posts.filter(post =>
      post.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setSearchResults(result)
  }, [posts, searchTerm])

  if (posts.length === 0) {
    return (
      <div>
        <p>No blog posts found.</p>
      </div>
    )
  }

  return (
    <div>
      <Seo
        title={intl.formatMessage({ id: "header_intl.101trash" })}
        description={intl.formatMessage({ id: "header_intl.seo" })}
      />
      <Header state={"search"} term={searchTerm} handle={handleChange} />
      <ContainerStyle fluid>
        <div
          className="d-flex justify-content-center py-3"
          ref={trashContainerParentRef}
        >
          <div
            className="d-flex flex-wrap"
            style={{
              width: trashContainerWidth ? `${trashContainerWidth}px` : "100%",
            }}
          >
            {searchResults.map((searchResult, index) => (
              <TrashItem searchResult={searchResult} index={index} />
            ))}
          </div>
        </div>
      </ContainerStyle>
      <div className="section fp-auto-height">
        <Footer state="mainpagefooter" />
      </div>
    </div>
  )
}

export default Mainpage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    en: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "english" } } }
      sort: { fields: [frontmatter___trashnumber], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          trashnumber
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    bm: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "malay" } } }
      sort: { fields: [frontmatter___trashnumber], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          trashnumber
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    tm: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "tamil" } } }
      sort: { fields: [frontmatter___trashnumber], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          trashnumber
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
    cn: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "mandarin" } } }
      sort: { fields: [frontmatter___trashnumber], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          trashnumber
          title
          language
          trashimage {
            publicURL
          }
        }
      }
    }
  }
`
